import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import Cookies from 'universal-cookie';
import {FormattedMessage, useIntl } from 'react-intl';
import { RouteContext } from "../../contexts/route.context";
import defaultImage from "../../assets/images/profpic.svg";


const Login = () => {
  const intl = useIntl();
  const cookies = new Cookies();
  const { baseURL, } = useContext(RouteContext);
  const [loginInput, setLoginInput] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [newUserError, setNewUserError] = useState(false);


  const sendOtp = async (e) => {
    e.preventDefault();
    setErrorShow(false);
    setErrorMsg("");
    setNewUserError(false);
    let roleId = 4; //lessee

    let result = await axios({
      url: baseURL,
      method: "post",
      data: {
        query: `
          mutation {
            sendOtp(input:{
              phone: "${loginInput}",
              role_id: "${roleId}",
              request_type: login
            }){
              code
              new_user
              status
              token
            }
          }
        `
      }
    });
    if(result.data.errors) {

      if(result.data.errors[0].extensions.category === "validation") {
        let errorMsg = result.data.errors[0].extensions.validation;
        let errorMsgArray = new Array();
        Object.keys(errorMsg).map((key) => {
          errorMsgArray.push(errorMsg[key][0]);
        });

        setErrorShow(true);
        setErrorMsg(errorMsgArray);

        console.log("LL",errorMsgArray)

      }
      else if(result.data.errors[0].extensions.category === "custom") {
        let errorMsg = result.data.errors[0].extensions.reason;
        // let errorMsgArray = new Array();
        // Object.keys(errorMsg).map((key) => {
        //   errorMsgArray.push(errorMsg[key][0]);
        // });

        setErrorShow(true);
        setErrorMsg(errorMsg);

        console.log(errorMsg)

      }
      else {
        console.log(result.data.errors[0].message)
      }

    }
    else if(result.data.data) {
      console.log("gggg",result.data.data)
      if(result.data.data.sendOtp.new_user === null) {
        setNewUserError(true);
      }
      else {
        cookies.set('phoneNumber', loginInput);
        if(cookies.get('phoneNumber') === "4545454545" || cookies.get('phoneNumber') === "0553470009" || cookies.get('phoneNumber') === "0509103111"){
          console.log("OTP", result.data.data.sendOtp.code)
          cookies.set('code', result.data.data.sendOtp.code);
        }
        window.location.href = "/verification";
        console.log("success")
      }
      
    }
  }


  return (
    <div>
      <div className="container-fluid no-header-div">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="login-content-div">
              <a href="/">
                <img alt="" src="assets/images/logo.svg" />
              </a>
              <h1 className="cblue fw700 login-title">
                <FormattedMessage 
                  id="readyengage.text"
                  defaultMessage="Ready to engage with Renter?"
                  description="Ready to engage with Renter?"
                />
              </h1>
              <p className="fw500 cgray mb-4 fs18">
                <FormattedMessage 
                  id="plslogin.text"
                  defaultMessage="Please log in"
                  description="Please log in"
                />
              </p>

              <div className="mb-3">
                <label className="fw600 cblue mb-2 fs18">
                  <svg width="15" height="15" style={{marginRight:"10px", marginLeft:"10px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0H4.7295C5.08456 0.000163454 5.42805 0.126271 5.69887 0.355889C5.96968 0.585508 6.15027 0.903748 6.2085 1.254L7.3185 7.9065C7.37154 8.22333 7.32146 8.54881 7.17564 8.83505C7.02983 9.12128 6.79597 9.35314 6.5085 9.4965L4.1865 10.656C5.01916 12.7195 6.25925 14.5939 7.83266 16.1673C9.40608 17.7408 11.2805 18.9808 13.344 19.8135L14.505 17.4915C14.6483 17.2043 14.8799 16.9706 15.1658 16.8248C15.4518 16.679 15.7769 16.6288 16.0935 16.6815L22.746 17.7915C23.0963 17.8497 23.4145 18.0303 23.6441 18.3011C23.8737 18.572 23.9998 18.9154 24 19.2705V22.5C24 22.8978 23.842 23.2794 23.5607 23.5607C23.2794 23.842 22.8978 24 22.5 24H19.5C8.73 24 0 15.27 0 4.5V1.5Z" fill="#1036CE"/>
                  </svg>
                  <FormattedMessage 
                    id="mobilenumber.text"
                    defaultMessage="Mobile Number"
                    description="Mobile Number"
                  />
                </label>
                <input type="text" className="form-control input-login" autocomplete="off" placeholder={intl.formatMessage({
                  id: 'entermobile.text',
                  defaultMessage: "Enter mobile number",
                  description: "Enter mobile number",
                })} value={loginInput} onChange={(e) => setLoginInput(e.target.value)} />
                
                {/* show error msgs */}
                {newUserError ? (
                  <p className="cred2 mt-2">
                    <svg width="18" height="18" style={{marginRight:"10px", marginLeft:"10px"}} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="18" height="18" rx="9" fill="#FF0000"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.86348 3.86363C4.03226 3.6949 4.26114 3.60012 4.49978 3.60012C4.73843 3.60012 4.96731 3.6949 5.13608 3.86363L8.99978 7.72733L12.8635 3.86363C12.9465 3.77767 13.0458 3.7091 13.1556 3.66194C13.2654 3.61477 13.3835 3.58994 13.503 3.5889C13.6225 3.58786 13.741 3.61063 13.8516 3.65589C13.9622 3.70114 14.0627 3.76797 14.1472 3.85247C14.2317 3.93697 14.2986 4.03746 14.3438 4.14807C14.3891 4.25867 14.4118 4.37719 14.4108 4.49669C14.4098 4.61619 14.3849 4.73429 14.3378 4.84409C14.2906 4.95389 14.222 5.0532 14.1361 5.13623L10.2724 8.99993L14.1361 12.8636C14.3 13.0334 14.3907 13.2607 14.3887 13.4967C14.3866 13.7327 14.292 13.9584 14.1251 14.1253C13.9583 14.2921 13.7325 14.3868 13.4965 14.3888C13.2606 14.3909 13.0332 14.3002 12.8635 14.1362L8.99978 10.2725L5.13608 14.1362C4.96634 14.3002 4.739 14.3909 4.50302 14.3888C4.26705 14.3868 4.04131 14.2921 3.87445 14.1253C3.70758 13.9584 3.61293 13.7327 3.61088 13.4967C3.60883 13.2607 3.69954 13.0334 3.86348 12.8636L7.72718 8.99993L3.86348 5.13623C3.69476 4.96745 3.59998 4.73857 3.59998 4.49993C3.59998 4.26128 3.69476 4.0324 3.86348 3.86363Z" fill="white"/>
                    </svg>
                    <FormattedMessage 
                      id="mobilenotregweb.text"
                      defaultMessage="This mobile number is currently not registered to our website"
                      description="This mobile number is currently not registered to our website"
                    />
                  </p>
                ) : ""}

                {errorShow ? (
                  <p className="cred2 mt-2">
                    <svg width="18" height="18" style={{marginRight:"10px", marginLeft:"10px"}} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="18" height="18" rx="9" fill="#FF0000"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.86348 3.86363C4.03226 3.6949 4.26114 3.60012 4.49978 3.60012C4.73843 3.60012 4.96731 3.6949 5.13608 3.86363L8.99978 7.72733L12.8635 3.86363C12.9465 3.77767 13.0458 3.7091 13.1556 3.66194C13.2654 3.61477 13.3835 3.58994 13.503 3.5889C13.6225 3.58786 13.741 3.61063 13.8516 3.65589C13.9622 3.70114 14.0627 3.76797 14.1472 3.85247C14.2317 3.93697 14.2986 4.03746 14.3438 4.14807C14.3891 4.25867 14.4118 4.37719 14.4108 4.49669C14.4098 4.61619 14.3849 4.73429 14.3378 4.84409C14.2906 4.95389 14.222 5.0532 14.1361 5.13623L10.2724 8.99993L14.1361 12.8636C14.3 13.0334 14.3907 13.2607 14.3887 13.4967C14.3866 13.7327 14.292 13.9584 14.1251 14.1253C13.9583 14.2921 13.7325 14.3868 13.4965 14.3888C13.2606 14.3909 13.0332 14.3002 12.8635 14.1362L8.99978 10.2725L5.13608 14.1362C4.96634 14.3002 4.739 14.3909 4.50302 14.3888C4.26705 14.3868 4.04131 14.2921 3.87445 14.1253C3.70758 13.9584 3.61293 13.7327 3.61088 13.4967C3.60883 13.2607 3.69954 13.0334 3.86348 12.8636L7.72718 8.99993L3.86348 5.13623C3.69476 4.96745 3.59998 4.73857 3.59998 4.49993C3.59998 4.26128 3.69476 4.0324 3.86348 3.86363Z" fill="white"/>
                    </svg>
                    {errorMsg.toString()}
                  </p>
                ) : ""}
                
                
                <button className="btn btn-sendotp" disabled={loginInput === "" ? true : false} onClick={sendOtp}>Send OTP</button>
              </div>

              <p className="go-visitor-text text-center mb-5 fs18">
                <a href="/" className="fw600 cblue fs18">
                  <FormattedMessage 
                    id="goguest.text"
                    defaultMessage="Go as a guest"
                    description="Go as a guest"
                  />
                </a>
              </p>

              <p className="text-center cgray fs18">
                <FormattedMessage 
                  id="donthaveacc.text"
                  defaultMessage="Don’t have an account?"
                  description="Don’t have an account?"
                /> 
                <a href="step1" className="fw600 cblue"> <FormattedMessage 
                  id="regnow.text"
                  defaultMessage="Register now"
                  description="Register now"
                /> </a>
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <img src="assets/images/login-img.png" alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;

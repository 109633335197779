import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import Cookies from 'universal-cookie';
import { FirebaseContext } from "../../contexts/firebase.context";
import OneSignal from "react-onesignal";
import Swal from 'sweetalert2';
import {FormattedMessage} from 'react-intl';
import { Modal, } from 'react-bootstrap';
import { RouteContext } from "../../contexts/route.context";
import { handleFocusNext, onKeyUpRemoveOtp, maskCharacter } from "../../util/helper";


const VerificationLessor = () => {
  const cookies = new Cookies();
  const { auth } = useContext(FirebaseContext);
  const { baseURL, } = useContext(RouteContext);
  let otpCode = cookies.get('code');
  let phoneNumber = cookies.get('phoneNumber');
  const [counter, setCounter] = useState(10);
  const [otpLastInput, setOtpLastInput] = useState("");
  const [otpOne, setOtpOne] = useState("")
  const [otpTwo, setOtpTwo] = useState("")
  const [otpThree, setOtpThree] = useState("")
  const [otpError, setOtpError] = useState(false);
  const [otpCodeShow, setOtpCodeShow] = useState(otpCode);

  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [fbEmail, setFbEmail] = useState();
  const [fbPassword, setFbPassword] = useState();
  const [userId, setUserId] = useState();
  const [userRoleId, setUserRoleId] = useState();
  const [userToken, setUserToken] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const buttonOneRef = useRef();
  const buttonTwoRef = useRef();
  const buttonThreeRef = useRef();
  const buttonFourRef = useRef();

  const handleCloseModalSuccess = () => setShowModalSuccess(false);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  useEffect(()=>{
    if(otpOne !== "" && otpTwo !== "" && otpThree !== "" && otpLastInput !== ""){
      onSubmitOtp()
    }
  },[otpLastInput]);


  const resendCode = async () => {
    setCounter(10);
    let roleId = 4; //lessee

    let result = await axios({
      url: baseURL,
      method: "post",
      data: {
        query: `
          mutation {
            sendOtp(input:{
              phone: "${phoneNumber}",
              role_id: "${roleId}",
              request_type: login
            }){
              code
              new_user
              status
              token
            }
          }
        `
      }
    });

    if (result.data.data) {
      cookies.set('code', result.data.data.sendOtp.code);
      setOtpCodeShow(result.data.data.sendOtp.code);
    }
    else {
      Swal.fire({
        icon: "warning",
        title: result.data.errors[0].message,
        text:
          result.data.errors[0].extensions.reason,
      });
    }
    
  }

  const onSubmitOtp = async (e) => {
    
    if(buttonLoading === true) {
      buttonOneRef.current.blur()
      buttonTwoRef.current.blur()
      buttonThreeRef.current.blur()
      buttonFourRef.current.blur()
  
      setButtonLoading(false);
      // e.preventDefault();
      setOtpError(false);
  
      let roleId = 3; //lessor
      // const formData = new FormData(e.target);
      // let formdataObj = Object.fromEntries(formData.entries());
      // const otpPass = formdataObj.otp1 + formdataObj.otp2 + formdataObj.otp3 + formdataObj.otp4;
      const otpPass = otpOne + otpTwo + otpThree + otpLastInput;
  
      let result = await axios({
        url: baseURL,
        method: "post",
        data: {
          query: `
            mutation {
              login(input:{
                phone: "${phoneNumber}",
                activation_code: "${otpPass}",
                role_id: "${roleId}"
              }){
                code
                token
                user {
                  id
                  created_at
                  role {
                    id
                  }
                }
              }
            }
          `
        }
      });
  
  
      // console.log(result)
      if(result.data.data) {
        buttonOneRef.current.disabled = true;
        buttonTwoRef.current.disabled = true;
        buttonThreeRef.current.disabled = true;
        buttonFourRef.current.disabled = true;
        let btoa = require('btoa');
  
        let fb_email = `fb_user${result.data.data.login.user?.id}@renterapp.com`
        let fb_password = btoa(result.data.data.login.user?.created_at);
  
        auth
        .signInWithEmailAndPassword(fb_email, fb_password)
        .then(() => {
        
          cookies.set('userTkn', result.data.data.login.token);
  
          OneSignal.init({ appId: '725b7c40-3eb6-4fcf-a03d-d8fd6fabccac' }).then(() => {
            // one signal send tags
            OneSignal.addTags({
              userId: "userId_" + result.data.data.login.user.id, 
              userType: result.data.data.login.user.role.id, 
              platform: "web"
            }, function(tagsSent) {
              console.log(tagsSent)
            });
          });
  
          window.location.href = "/";
        })
        .catch(function (error) {
          // if no registered firebase account yet
          let btoa = require('btoa');
  
          let fb_email = `fb_user${result.data.data.login.user?.id}@renterapp.com`
          let fb_password = btoa(result.data.data.login.user?.created_at);
  
          auth.createUserWithEmailAndPassword(fb_email, fb_password)
          .then(function () {
  
              setFbEmail(fb_email);
              setFbPassword(fb_password);
              setUserId(result.data.data.login.user.id);
              setUserRoleId(result.data.data.login.user.role.id);
              setUserToken(result.data.data.login.token);
              setShowModalSuccess(true);
  
          }).catch(function (e) {
            console.log(e)
            Swal.fire({
              icon: "warning",
              title: "Error Firebase Registration",
              text:
                e,
            });
          });
        });
      }
      else {
        setOtpError(true);
      }
    }
    
  }


  const onClickProceed = () => {
    auth
    .signInWithEmailAndPassword(fbEmail, fbPassword)
    .then(() => {

      OneSignal.init({ appId: '725b7c40-3eb6-4fcf-a03d-d8fd6fabccac' }).then(() => {
        // one signal send tags
        OneSignal.addTags({
          userId: "userId_" + userId, 
          userType: userRoleId, 
          platform: "web"
        }, function(tagsSent) {
          console.log(tagsSent)
        });
      });

      cookies.set('userTkn', userToken);
      window.location.href = "/";
    })
    .catch(function (error) {
      Swal.fire({
        title: 'Error Firebase login',
        text: '',
        icon: 'warning',
        confirmButtonColor: '#ff9035',
      });
    });
  }


  return (
    <div>
      <div className="container main-wrap no-header-div">
        <div className="row">
          <div className="col-12">
            <a href="/">
              <img alt="" src="assets/images/logo.svg" />
            </a>
            <form className="verification-div text-center" onSubmit={onSubmitOtp}>
              <h1 className="cblue fw700 login-title">
                <FormattedMessage 
                  id="authencode.text"
                  defaultMessage="Authentication Code"
                  description="Authentication Code"
                />
              </h1>
              <p className="cgray fw500">
                <FormattedMessage 
                  id="confirmauthcode.text"
                  defaultMessage="Please confirm your account by entering the authentication code sent to"
                  description="Please confirm your account by entering the authentication code sent to"
                /><span> </span><span className="cblack">{maskCharacter(phoneNumber, '*', 3)}</span>
                </p>
              
              {/* <p className="cred2 mt-2">
                For development purpose only OTP code: {otpCodeShow}
              </p> */}
              <div className="veri-input-div mt-5 mb-5">
                <input ref={buttonOneRef} type="text" className="form-control veriForm-input" maxLength="1" name="otp1" id="otp1" value={otpOne} onChange={(e) => 
                  {
                  setOtpOne(e.target.value);
                  handleFocusNext(e, 1, "otp2");
                  }}/>
                <input ref={buttonTwoRef} type="text" className="form-control veriForm-input" maxLength="1" name="otp2" id="otp2" value={otpTwo} onChange={(e) => 
                  {
                    setOtpTwo(e.target.value);
                    handleFocusNext(e, 1, "otp3")
                  }} onKeyUp={(e) => onKeyUpRemoveOtp(e, "otp1")}/>
                <input ref={buttonThreeRef} type="text" className="form-control veriForm-input" maxLength="1" name="otp3" id="otp3" value={otpThree} onChange={(e) => 
                {
                    setOtpThree(e.target.value)
                    handleFocusNext(e, 1, "otp4")
                }} onKeyUp={(e) => onKeyUpRemoveOtp(e, "otp2")}/>
                <input ref={buttonFourRef} type="text" className="form-control veriForm-input" maxLength="1" name="otp4" id="otp4" onKeyUp={(e) => onKeyUpRemoveOtp(e, "otp3")} value={otpLastInput} onChange={(e) => 
                  {
                    setOtpLastInput(e.target.value)
                    setButtonLoading(true);
                  }}/>
              </div>
              {/* show error msg */}
              {otpError ? (
                <p className="cred2 mt-3">
                  <svg width="18" height="18" style={{marginRight:"10px", marginLeft:"10px"}} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="18" height="18" rx="9" fill="#FF0000"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.86348 3.86363C4.03226 3.6949 4.26114 3.60012 4.49978 3.60012C4.73843 3.60012 4.96731 3.6949 5.13608 3.86363L8.99978 7.72733L12.8635 3.86363C12.9465 3.77767 13.0458 3.7091 13.1556 3.66194C13.2654 3.61477 13.3835 3.58994 13.503 3.5889C13.6225 3.58786 13.741 3.61063 13.8516 3.65589C13.9622 3.70114 14.0627 3.76797 14.1472 3.85247C14.2317 3.93697 14.2986 4.03746 14.3438 4.14807C14.3891 4.25867 14.4118 4.37719 14.4108 4.49669C14.4098 4.61619 14.3849 4.73429 14.3378 4.84409C14.2906 4.95389 14.222 5.0532 14.1361 5.13623L10.2724 8.99993L14.1361 12.8636C14.3 13.0334 14.3907 13.2607 14.3887 13.4967C14.3866 13.7327 14.292 13.9584 14.1251 14.1253C13.9583 14.2921 13.7325 14.3868 13.4965 14.3888C13.2606 14.3909 13.0332 14.3002 12.8635 14.1362L8.99978 10.2725L5.13608 14.1362C4.96634 14.3002 4.739 14.3909 4.50302 14.3888C4.26705 14.3868 4.04131 14.2921 3.87445 14.1253C3.70758 13.9584 3.61293 13.7327 3.61088 13.4967C3.60883 13.2607 3.69954 13.0334 3.86348 12.8636L7.72718 8.99993L3.86348 5.13623C3.69476 4.96745 3.59998 4.73857 3.59998 4.49993C3.59998 4.26128 3.69476 4.0324 3.86348 3.86363Z" fill="white"/>
                  </svg>
                  <FormattedMessage 
                    id="invalidotp.text"
                    defaultMessage="Invalid OTP entered. Please try again."
                    description="Invalid OTP entered. Please try again."
                  />
                </p>
              ) : ""}

              {/* {
                !buttonLoading ?
              <button type="submit" className="btn btn-verification mb-4" disabled={otpError === false ? true : false}>
                <FormattedMessage 
                  id="login.text"
                  defaultMessage="Login"
                  description="Login"
                />
              </button>
              :
              ""
              } */}

              <p className="fw500 cgray veri-send-text">
                <FormattedMessage 
                  id="haventrececode.text"
                  defaultMessage="It may take a few minutes to receive your code. Haven’t received it?"
                  description="It may take a few minutes to receive your code. Haven’t received it?"
                />
              </p>

              <div className="resendContainer">
                <p className={`${counter <= 0 ? "sendcode-active" : "cgray"} mt-4`} onClick={counter <= 0 ? resendCode : null}>
                {/* <span >  */}
                  <FormattedMessage 
                    id="resendcode.text"
                    defaultMessage="Resend a new code"
                    description="Resend a new code"
                  />
                  {/* </span> */}
                </p>
                
                <p className="fw500 cred mt-4"><FormattedMessage 
                  id="resendin.text"
                  defaultMessage="Resend in"
                  description="Resend in"
                /> {counter}s</p>
              </div>
            </form>
          </div>
        </div>
      </div>


      <Modal className="modalChat" show={showModalSuccess} onHide={handleCloseModalSuccess}>
        <Modal.Body>
          <div className="d-flex justify-content-center py-5">
            <svg width="126" height="108" viewBox="0 0 176 158" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M68.1989 157.854C105.695 157.854 136.091 127.458 136.091 89.9616C136.091 52.4658 105.695 22.0693 68.1989 22.0693C30.7031 22.0693 0.306641 52.4658 0.306641 89.9616C0.306641 127.458 30.7031 157.854 68.1989 157.854Z" fill="#DFE4FE"/>
              <path d="M68.9656 142.437L68.1044 140.97C54.9506 118.558 19.996 70.9977 19.6429 70.52L19.1387 69.835L31.0475 58.0657L68.7444 84.3884C92.4794 53.5888 114.623 32.4342 129.066 20.0807C144.867 6.56726 155.152 0.346101 155.256 0.286485L155.489 0.146484H175.69L173.761 1.86495C124.134 46.0673 70.3441 140.015 69.8083 140.959L68.9656 142.437Z" fill="#1036CE"/>
            </svg>
          </div>

          <h3 className="fw700 fs30 text-center cblue mb-4">
            <FormattedMessage 
              id="fbacccreated.text"
              defaultMessage="Firebase Account created!"
              description="Firebase Account created!"
            />
          </h3>
          <p className="fs16 px-2 text-center cblack">
            <FormattedMessage 
              id="pressloginsucc.text"
              defaultMessage="Press proceed to login Successfully"
              description="Press proceed to login Successfully"
            />
          </p>
          

          <button className="btn btn-payment w-100 mt-3" onClick={onClickProceed}>
            <FormattedMessage 
              id="proceed.text"
              defaultMessage="Proceed"
              description="Proceed"
            />
          </button>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default VerificationLessor;

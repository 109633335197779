import React, { useState, useEffect, useContext, useRef } from "react";
import OneSignal from "react-onesignal";
import {FormattedMessage,} from 'react-intl';
import Pagination from "./pagination";
import { RouteContext } from "../contexts/route.context";
import { useIntersection } from "../util/useIntersection";


const Notification = () => {
  const { getUserNotifications, userNotifications, userNotificationsLoading, setUserNotificationsLoading, } = useContext(RouteContext);
  const [listNotifications, setListNotifications] = useState([]);
  const [numLease, setNumLease] = useState(12);
  const [pageLease, setpageLease] = useState(1);
  const [notif, setNotif] = useState();
  const elementRef = useRef(null);
  const [moreLoading, setMoreLoading] = useState(false);

  const isVisible = useIntersection(elementRef, "0px 0px 10% 0px");

  useEffect(() => {
    setUserNotificationsLoading(true);
    getUserNotif();
  }, []);

  const getUserNotif = async () => {
    let x = await getUserNotifications(numLease, pageLease);
    setNotif(x);
    console.log("data", notif);
  }

  useEffect(() => {
    if (isVisible) {
    triggerFunc();
    }
  }, [isVisible]);

  const triggerFunc = () => {
    if(notif){
        if(notif?.paginatorInfo.hasMorePages){
            // setNoMorePosts(false);
            scrollPosts();
        }
    }
  }

  const scrollPosts = async () => {
    try{
      setMoreLoading(true);
      let result = await getUserNotifications(numLease, notif.paginatorInfo.currentPage+1);
      if(notif.paginatorInfo.hasMorePages){
        result.data.map((dat)=>{
          notif.data.push(dat);
        })
        notif.paginatorInfo = result.paginatorInfo;
      }
      setMoreLoading(false);
    }
    catch(err){
      console.log(err);
    }
  }

  useEffect(() => {
    // get one signal notifications
    OneSignal.on('notificationDisplay', function(event) {
      let notiArray = new Array();
      notiArray = [...listNotifications];
      notiArray.push(event);
      setListNotifications(notiArray);

      console.warn('OneSignal notification displayed:', event);
    });
  }, []);


  return (
    <div>
      <div className="container-fluid leases-single-div">
        <div className="container main-wrap">
          <div className="row">
            <div className="col-12">
              
              <div className="noti-div mt-5">
                <h2 className="fw600 cblue mb-5 pt-3 text-center">
                  <FormattedMessage 
                    id="noti.text"
                    defaultMessage="Notification"
                    description="Notification"
                  />
                </h2>


                {/* when no notification */}
                {/* <div className="row mt-5 pt-5">
                  <div className="col-12">
                    <div className="text-center">
                      <img src="assets/images/no-lease-img.png" className="img-fluid pt-5 mb-4" />
                      <p className="fs30 fw700 cgray">You have no Notification</p>
                    </div>
                  </div>
                </div> */}
                {/* when no notification */}


                {!userNotificationsLoading && notif && notif?.data?.length !== 0 ? (
                  <>
                  {notif?.data?.map((noti, index) => (
                    <div key={index} className="noti-per-div">
                      <div className="noti-img">
                        <img src={noti.image ? noti.image : "assets/images/noti-img.png"} className="imgenclose" />
                      </div>
                      <div className="noti-desc">
                        <span className="noti-bell">
                          <svg width="15" height="17" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 15H6M12 15H17L15.595 13.595C15.4063 13.4063 15.2567 13.1822 15.1546 12.9357C15.0525 12.6891 15 12.4249 15 12.158V9C15.0002 7.75894 14.6156 6.54834 13.8992 5.53489C13.1829 4.52144 12.17 3.75496 11 3.341V3C11 2.46957 10.7893 1.96086 10.4142 1.58579C10.0391 1.21071 9.53043 1 9 1C8.46957 1 7.96086 1.21071 7.58579 1.58579C7.21071 1.96086 7 2.46957 7 3V3.341C4.67 4.165 3 6.388 3 9V12.159C3 12.697 2.786 13.214 2.405 13.595L1 15H6H12ZM12 15V16C12 16.7956 11.6839 17.5587 11.1213 18.1213C10.5587 18.6839 9.79565 19 9 19C8.20435 19 7.44129 18.6839 6.87868 18.1213C6.31607 17.5587 6 16.7956 6 16V15H12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        </span>
                        <p className="fw700 fs16 cblack2 mb-1">{noti.title}</p>
                        <p className="fs14 cgray2 mb-1">{noti.message}</p>
                        <p className="fs14 cred mb-0">{noti.created_at}</p>
                      </div>
                    </div>
                  ))}
                    {
                    moreLoading && 
                        <div className="loader"></div>
                    }
                    
                  {/* <Pagination useFunction="getUserNotifications" posts={userNotifications} setPostsLoading={setUserNotificationsLoading} numLease={numLease}  /> */}
                  </>
                ) : (
                  <div className="loader"></div>
                )}


                {/* <div className="noti-per-div">
                  <div className="noti-img">
                    <img src="assets/images/noti-img.png" className="imgenclose" />
                  </div>
                  <div className="noti-desc">
                    <span className="noti-chat">
                      
                      <svg width="17" height="15" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 9H6.01H6ZM10 9H10.01H10ZM14 9H14.01H14ZM19 9C19 13.418 14.97 17 10 17C8.52864 17.005 7.07479 16.6808 5.745 16.051L1 17L2.395 13.28C1.512 12.042 1 10.574 1 9C1 4.582 5.03 1 10 1C14.97 1 19 4.582 19 9Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>

                    </span>
                    <p className="fw700 fs16 cblack2 mb-1">The Best Offer</p>
                    <p className="fs14 cgray2 mb-1">Praesent at blandit est. Donec vestibulum lorem ante, eu finibus elit pulvinar vel. Nullam in tortor pellentesque, posuere dolor sit amet, facilisis felis. Praesent nec ultrices tellus, ac consequat turpis. Aliquam rutrum pharetra augue, ac porttitor velit varius et. </p>
                    <p className="fs14 cred mb-0">March 8, 2022 1:01 PM</p>
                  </div>
                </div> */}

                

              </div>

            </div>
          </div>
        </div>
      </div>
      <div ref={elementRef}></div>
    </div>
  )
}

export default Notification;

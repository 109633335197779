import React, { useState, useEffect, useContext } from "react";
import { Navbar, Container, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import { RouteContext } from "../../contexts/route.context";
import logoFooter from '../../assets/images/logo-footer.svg';

const Footer = () => {
  const intl = useIntl();
  const { getCategories, categories, getAllSettings, allSettings } = useContext(RouteContext);

  useEffect(() => {
    getCategories();
    getAllSettings();
  }, []);


  return (
  <div className="footer-div">
    <div className="footer-up-div">
      <div className="container main-wrap">
        <div className="row m-auto">
          <div className="col-12 col-md-6 col-lg-4 mb-5 d-flex">
            {/* <img alt="" src="assets/images/logo-footer.svg" className="footer-logo-img m-auto"/> */}
            <img alt="" src={logoFooter} className="footer-logo-img m-auto"/>
          </div>
          <div className="col-12 col-md-6 col-lg-2 mb-5 d-flex flex-column">
            <p className="mb-2 fs16 fw700 cwhite">
              <FormattedMessage 
                id="leases.text"
                defaultMessage="Leases"
                description="Leases"
              />
            </p>
            {categories && categories.map((cat, index) => (
              <p key={index} className="mb-1 cwhite">
                <a className="cwhite " href="/leases">{intl.locale === "en" ? cat.en : cat.ar}</a>
              </p>
            ))}
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-5 d-flex flex-column">
            <p className="mb-2 fs16 fw700 cwhite">
              <FormattedMessage 
                id="helpinfo.text"
                defaultMessage="Help and Information"
                description="Help and Information"
              />
            </p>
            <p className="mb-1 cwhite">
              <a href="/about" className="cwhite">
                <FormattedMessage 
                  id="aboutus.text"
                  defaultMessage="About Us"
                  description="About Us"
                />
              </a>
            </p>
            <p className="mb-1 cwhite">
              <a href="/terms" className="cwhite">
                <FormattedMessage 
                  id="termscond.text"
                  defaultMessage="Terms and Conditions"
                  description="Terms and Conditions"
                />
              </a>
            </p>
            <p className="mb-1 cwhite">
              <a href="/cancellation-policy" className="cwhite">
                <FormattedMessage 
                  id="canpol.text"
                  defaultMessage="Cancellation Policy"
                  description="Cancellation Policy"
                />
              </a>
            </p>
            <p className="mb-1 cwhite">
              <a href="/privacy" className="cwhite">
                <FormattedMessage 
                  id="pripol.text"
                  defaultMessage="Privacy Policy"
                  description="Privacy Policy"
                />
              </a>
            </p>
          </div>
          {/* <div className="col-12 col-md-6 col-lg-3">
            <p className="mb-2 fs16 fw700 cwhite">
              <FormattedMessage 
                id="contactus.text"
                defaultMessage="Contact Us"
                description="Contact Us"
              />
            </p>
            <p className="mb-3 fs12 cwhite">
              <FormattedMessage 
                id="addquestions.text"
                defaultMessage="For any additional questions, feel free to contact us here"
                description="For any additional questions, feel free to contact us here"
              />
            </p>
            <input type="email" className="form-control footer-email" placeholder="Enter your email" />
          </div> */}
        </div>
      </div>
    </div>
    <div className="footer-bottom-div">
      <div className="container main-wrap">
        <div className="row">
          <div className="col-12 col-lg-6">
            <p className="mb-0 cgray fs16">Renter © Copyright 2025, Inc. All rights reserved. Version 1.3.5</p>
          </div>
          <div className="col-12 col-lg-6">
            
              {allSettings && (
                <div className="d-flex float-sm-end">
                <a href={allSettings.social_link_3} target="_blank" rel="noreferrer" className="me-3">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 448 512"><path d="M194.4 211.7a53.3 53.3 0 1 0 59.3 88.7 53.3 53.3 0 1 0 -59.3-88.7zm142.3-68.4c-5.2-5.2-11.5-9.3-18.4-12c-18.1-7.1-57.6-6.8-83.1-6.5c-4.1 0-7.9 .1-11.2 .1c-3.3 0-7.2 0-11.4-.1c-25.5-.3-64.8-.7-82.9 6.5c-6.9 2.7-13.1 6.8-18.4 12s-9.3 11.5-12 18.4c-7.1 18.1-6.7 57.7-6.5 83.2c0 4.1 .1 7.9 .1 11.1s0 7-.1 11.1c-.2 25.5-.6 65.1 6.5 83.2c2.7 6.9 6.8 13.1 12 18.4s11.5 9.3 18.4 12c18.1 7.1 57.6 6.8 83.1 6.5c4.1 0 7.9-.1 11.2-.1c3.3 0 7.2 0 11.4 .1c25.5 .3 64.8 .7 82.9-6.5c6.9-2.7 13.1-6.8 18.4-12s9.3-11.5 12-18.4c7.2-18 6.8-57.4 6.5-83c0-4.2-.1-8.1-.1-11.4s0-7.1 .1-11.4c.3-25.5 .7-64.9-6.5-83l0 0c-2.7-6.9-6.8-13.1-12-18.4zm-67.1 44.5A82 82 0 1 1 178.4 324.2a82 82 0 1 1 91.1-136.4zm29.2-1.3c-3.1-2.1-5.6-5.1-7.1-8.6s-1.8-7.3-1.1-11.1s2.6-7.1 5.2-9.8s6.1-4.5 9.8-5.2s7.6-.4 11.1 1.1s6.5 3.9 8.6 7s3.2 6.8 3.2 10.6c0 2.5-.5 5-1.4 7.3s-2.4 4.4-4.1 6.2s-3.9 3.2-6.2 4.2s-4.8 1.5-7.3 1.5l0 0c-3.8 0-7.5-1.1-10.6-3.2zM448 96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96zM357 389c-18.7 18.7-41.4 24.6-67 25.9c-26.4 1.5-105.6 1.5-132 0c-25.6-1.3-48.3-7.2-67-25.9s-24.6-41.4-25.8-67c-1.5-26.4-1.5-105.6 0-132c1.3-25.6 7.1-48.3 25.8-67s41.5-24.6 67-25.8c26.4-1.5 105.6-1.5 132 0c25.6 1.3 48.3 7.1 67 25.8s24.6 41.4 25.8 67c1.5 26.3 1.5 105.4 0 131.9c-1.3 25.6-7.1 48.3-25.8 67z"/></svg>
                </a>
                <a href={allSettings.social_link_4} target="_blank" rel="noreferrer" className="me-3">
                  {/* <img alt="" src="assets/images/linkedin.svg" /> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#000000"><path d="M22.23 0H1.77C.8 0 0 .77 0 1.72v20.56C0 23.23.8 24 1.77 24h20.46c.98 0 1.77-.77 1.77-1.72V1.72C24 .77 23.2 0 22.23 0zM7.27 20.1H3.65V9.24h3.62V20.1zM5.47 7.76h-.03c-1.22 0-2-.83-2-1.87 0-1.06.8-1.87 2.05-1.87 1.24 0 2 .8 2.02 1.87 0 1.04-.78 1.87-2.05 1.87zM20.34 20.1h-3.63v-5.8c0-1.45-.52-2.45-1.83-2.45-1 0-1.6.67-1.87 1.32-.1.23-.11.55-.11.88v6.05H9.28s.05-9.82 0-10.84h3.63v1.54a3.6 3.6 0 0 1 3.26-1.8c2.39 0 4.18 1.56 4.18 4.89v6.21z"/></svg>
                </a>
                <a href={allSettings.social_link_2} target="_blank" rel="noreferrer" className="me-2">
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                </a>
                {/* <a href="#" style={{marginTop:"-2px"}}>
                  <img alt="" src="assets/images/whatsapp.svg" />
                </a> */}
                </div>
              )}
              
            
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Footer
